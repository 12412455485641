import DashBoardLinks from "../../Components/DashBoardLinks/DashBoardLinks";

const AdminDashboard = ()=>{

    return(
        <div>
            <DashBoardLinks/>
        </div>
    )
}


export default AdminDashboard;