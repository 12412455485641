import "./index.css"
import Signin from "../../Components/AdminSignin/SignIn";

const AdminSignIn= ()=>{

    return(

        <div className="adminPageContainer">
            <Signin />

        </div>
    )
}


export default AdminSignIn;